import React from 'react'

const CategoryHeader = ({subsinfo}) => {
    console.log(subsinfo, 'subs')
  return (
    <div className='containerMain'>
        <div className='headerText'>
        {subsinfo?.display_name}
        </div>
    
    </div>
  )
}

export default CategoryHeader
