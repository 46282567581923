import axios from "axios"
import { baseUrlV2 } from "../../apiConstant"

export const GET_SEARCH_REDDIT_POST = 'GET_SEARCH_REDDIT_POST'
const NavSearch = (inputValue, checked) => {
    return async(dispatch) => {
        try{
            const response =  await axios.get(`${baseUrlV2}/subreddits/search.json?q=${inputValue}&include_over_18=on&limit=150&t=all`)
            if(response) {
                console.log(response, 'search response')
                return dispatch({
                    type: GET_SEARCH_REDDIT_POST,
                    payload: response?.data?.data?.children
                })
            }
        } catch(error) {
            throw error;
        }
    }
}

export default NavSearch;