import axios from "axios"
import { baseUrlV2 } from "../../apiConstant"



export const GET_DETAIL_POST = 'GET_DETAIL_POST'
const DetailPost = (id) => {
    return async(dispatch) => {
        try{
            const response =  await axios.get(`${baseUrlV2}/${id}.json`)
            if(response) {
                return dispatch({
                    type: GET_DETAIL_POST,
                    payload: response?.data[0]?.data?.children[0]
                })
            }
        } catch(error) {
            throw error;
        }
    }
}

export default DetailPost