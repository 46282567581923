import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DetailPost from '../Redux/actions/DetailPost';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const Detail = ({ DetailPost, detail }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDetail = async () => {
            setLoading(true);
            await DetailPost(id);
            setLoading(false);
        };
        fetchDetail();
    }, [id, DetailPost]);

    const closeButton = () => {
        navigate(-1);
    };

    const downloadMedia = async () => {
        const mediaUrl = detail?.data?.data?.url_overridden_by_dest;

        if (mediaUrl) {
            try {
                const response = await fetch(mediaUrl, {
                    mode: 'cors',
                });
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                const fileName = detail?.data?.data?.title || 'downloaded-media';
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the object URL after download
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading the media:', error);
            }
        } else {
            console.error('Invalid media URL');
        }
    };

    return (
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>{`${detail?.data?.data?.title}`} </title>
                <meta name="description" content="Scrollway - Photos & Videos" />
                <meta name="keywords" content="Scrollway - Photos & Videos" />
                <meta name="author" content="Scrollway - Photos & Videos" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"
                />
                <link rel="canonical" href="https://scrollway.com/" />
        </Helmet>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <>
                    <div className="detailImage">
                        {detail?.data?.data?.post_hint === 'image' ? (
                            <LazyLoadImage src={detail?.data?.data?.url_overridden_by_dest} />
                        ) : (
                            detail?.data?.data?.post_hint === 'hosted:video' && (
                                <ReactPlayer
                                    url={detail?.data?.data?.media?.reddit_video?.hls_url?.replaceAll("&amp;", "&")}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    playing={true}
                                    playIcon={true}
                                />
                            )
                        )}
                    </div>
                    <div className="detailTitle">{detail?.data?.data?.title}</div>
                    <div className="detailSub">{detail?.data?.data?.subreddit}</div>
                    <div className="closebutton" onClick={closeButton}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                    </div>

                    <div className="detailbuttons">
                        <div className="download" onClick={downloadMedia}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        detail: state.detailpostReducers,
    };
};

const mapDisPatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            DetailPost,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDisPatchToProps)(Detail);
