import { combineReducers } from "redux";
import homereducers from "./homereducers";
import detailpostReducers from "./detailpostReducers";
import NavSearchReducers from "./NavSearchReducers";
import SubsinfoReducers from "./SubsinfoReducers";

const createReducer = (asyncReducers) => {
  const appReducer = combineReducers({
    homereducers,
    detailpostReducers,
    NavSearchReducers,
    SubsinfoReducers,
    ...asyncReducers
  });
  return (state, action) => {
    return appReducer(state, action);
  };
};

export default createReducer;