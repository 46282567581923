import './App.css';
import Navbar from './components/Navbar';
import About from './pages/About';
import Categories from './pages/Categories';
import Detail from './pages/Detail';
import Home from './pages/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import CategoryShow from './pages/CategoryShow';

function App() {
  const location = useLocation();
  
  return (
    <>
      <MantineProvider>
        {
          !location.pathname.includes('/detail') && <Navbar />
        }
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/detail/:id' element={<Detail />} />
          <Route path='/:id' element={<CategoryShow />} />
          <Route path='/categories' element={<Categories />} />
        </Routes>
      </MantineProvider>
    </>
  );
}

export default App;
