import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Badge, Divider, Image, Input, List, Modal, NavLink } from '@mantine/core';
import { bindActionCreators } from 'redux';
import NavSearch from '../Redux/actions/NavSearch';

const Navbar = ({ NavSearch, searchData }) => {
  const [search, setSearch] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (search.length > 2) {
      const debounceSearch = setTimeout(() => {
        try {
          NavSearch(search);
          setError(null);
        } catch (err) {
          setError('Failed to fetch search results.');
        }
      }, 300); // Debounce time of 300ms

      return () => clearTimeout(debounceSearch);
    }
  }, [search, NavSearch]);


  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num?.toString();
    }
  }

  return (
    <div className='NavbarConainer'>
      <div className='search'>
        <input placeholder='search' onClick={open} />
      </div>

      <div className='logo'>SROLLWAY</div>

      <div className='social'>
        <div>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path>
          </svg>
        </div>
        <div>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z"></path>
          </svg>
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        title="Search"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Input ref={inputRef} placeholder='Search Here' value={search} onChange={(e) => setSearch(e.target.value)} autoFocus={true} />
        {error && <div className="error">{error}</div>}
        {searchData?.data?.map((post) => (
          <React.Fragment key={post.data.id}>
            <SearchResult post={post} formatNumber={formatNumber} />
            <Divider />
          </React.Fragment>
        ))}
      </Modal>
    </div>
  );
};

const SearchResult = ({ post, formatNumber }) => (
  <NavLink
    href={`${post.data.display_name_prefixed.split('/')[1]}`}
    label={post.data.title}
    description={post.data.public_description}
    leftSection={
      <Image
        className='searchImage'
        src={post.data.icon_img || post.data.community_icon?.replaceAll('&amp;', '&') || post.data.banner_background_image?.replaceAll('&amp;', '&')}
      />
    }
    rightSection={
      <div className='rightsectionsearch'>
        <div className='nsfwtag'>{post.data.over18 ? '18+' : ''}</div>
        <div className='subscount'>{formatNumber(post.data.subscribers)} subs</div>
      </div>
    }
  />
);

const mapStateToProps = (state) => {
  return {
    searchData: state.NavSearchReducers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      NavSearch
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
