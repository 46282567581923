import axios from "axios"
import { baseUrl, baseUrlV2 } from "../../apiConstant"

export const GET_SUBS_INFO_POST = 'GET_SUBS_INFO_POST'
const SubsInfo = (subs) => {
    return async(dispatch) => {
        try{
            const response =  await axios.get(`${baseUrl}/${subs}/about.json`)
            if(response) {
                return dispatch({
                    type: GET_SUBS_INFO_POST,
                    payload: response?.data?.data
                })


            }
        } catch(error) {
            throw error;
        }
    }
}

export default SubsInfo;