import * as Actions from "../actions/HomeData";

const initialState = {
    data: []
};

const homereducers = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_HOME_POST: {
            return {
                ...state,
                data: [...state.data, ...action.payload]
            };
        }
        default: {
            return state;
        }
    }
};




export default homereducers;




