import React from 'react'

const Categories = () => {
  return (
    <div>
      Categories
    </div>
  )
}

export default Categories
