import axios from "axios"
import { baseUrl } from "../../apiConstant"

export const GET_HOME_POST = 'GET_HOME_POST'
const Homedata = (subs,after, page) => {
    return async(dispatch) => {
        try{
            const response =  await axios.get(`${baseUrl}/${subs}.json?after=${after}&page=${page}`)
            if(response) {
                return dispatch({
                    type: GET_HOME_POST,
                    payload: response?.data?.data?.children
                })
            }
        } catch(error) {
            throw error;
        }
    }
}

export default Homedata;