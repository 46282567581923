import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Homedata from '../Redux/actions/HomeData'
import Media from '../components/Media'
import CategoryHeader from '../components/CategoryHeader'
import SubsInfo from '../Redux/actions/SubsInfo'
import { Helmet } from 'react-helmet'

const CategoryShow = ({ categoryshowdata, Homedata, SubsInfo, SubsInformation }) => {
    const { id } = useParams()


    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        SubsInfo(id)

    }, [id])

    console.log(SubsInformation, 'aboutinfo')

    useEffect(() => {
        let isMounted = true; // To track if the component is mounted

        const fetchData = async () => {
            setLoading(true);
            await Homedata(id, categoryshowdata?.slice(-1)[0]?.data?.name, page);
            if (isMounted) { // Check if component is still mounted before setting state
                setLoading(false);
            }
        };
        fetchData();
        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [page]);


    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 500 && !loading
            ) {
                setLoading(true);
                setPage(prevPage => prevPage + 1);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading]);




    return (
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>{`${id} on scrollway`} </title>
                <meta name="description" content={`${id}}`} />
                <meta name="keywords" content={id} />
                <meta name="author" content="Scrollway - Photos & Videos" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"
                />
                <link rel="canonical" href="https://scrollway.com/" />
        </Helmet>
            <CategoryHeader subsinfo={SubsInformation} />
            <div className='mainContainer'>
                <Media home={categoryshowdata} />
                {loading &&
                    <div className='loaderIcon'>
                        <svg width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                            <circle cx="50" cy="50" r="35" stroke="currentColor" stroke-width="10" fill="none" opacity="0.2" />
                            <circle cx="50" cy="50" r="35" stroke="currentColor" stroke-width="6" fill="none" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 50 50"
                                    to="360 50 50"
                                    dur="1s"
                                    repeatCount="indefinite" />
                            </circle>
                        </svg>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        categoryshowdata: state?.homereducers?.data,
        SubsInformation: state?.SubsinfoReducers?.data
    };
};

const mapDisPatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            Homedata,
            SubsInfo
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDisPatchToProps)(CategoryShow)
