import React from 'react';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';

const Media = ({ home }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 2,
  };

  const extractImageUrl = (resolutions) => {
    return (
      resolutions[4]?.url ||
      resolutions[3]?.url ||
      resolutions[2]?.url ||
      resolutions[1]?.url ||
      resolutions[0]?.url
    )?.replace(/&amp;/g, '&');
  };

  const downloadMedia = async ({ id, title }) => {
    if (id) {
      try {
        const response = await fetch(id, {
          mode: 'cors',
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        const fileName = title || 'downloaded-media';
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL after download
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the media:', error);
      }
    } else {
      console.error('Invalid media URL');
    }
  };

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {home?.map((post) => {
          const isImagePost = post?.data?.post_hint === 'image';
          const isVideoPost = post?.data?.post_hint === 'hosted:video';
          const imageUrl = extractImageUrl(post?.data?.preview?.images[0]?.resolutions || []);

          return (isImagePost || isVideoPost) ? (
            <div key={post.data.id} className={`media-container ${isVideoPost ? 'videoMedia' : ''}`}>
              <Link to={`/detail/${post.data.id}`}>
                <img src={imageUrl} alt={post?.data?.title} />
                {isVideoPost && (
                  <div className='playButton'>
                    <svg stroke="currentColor" fill="white" strokeWidth="0" viewBox="0 0 1024 1024" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm144.1 454.9L437.7 677.8a8.02 8.02 0 0 1-12.7-6.5V353.7a8 8 0 0 1 12.7-6.5L656.1 506a7.9 7.9 0 0 1 0 12.9z"></path>
                    </svg>
                  </div>
                )}
              </Link>
              <button
                className="download-button"
                onClick={() => downloadMedia({
                  id: isImagePost ? imageUrl : post.data.secure_media.reddit_video.fallback_url,
                  title: post?.data?.title,
                })}
              >
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 16L16 11 13 11 13 4 11 4 11 11 8 11z"></path>
                  <path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z"></path>
                </svg>
              </button>
            </div>
          ) : null;
        })}
      </Masonry>
      <style jsx>{`
        .media-container {
          position: relative;
        }
        .videoMedia img {
          position: relative;
        }
        .playButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .download-button {
          position: absolute;
          right: 10px;
          bottom: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
        }
        .download-button:hover {
          background-color: rgba(0, 0, 0, 0.7);
        }
      `}</style>
    </div>
  );
};

export default Media;
